
  import {defineComponent} from 'vue';
  import UserPortalCheckBox from "@/components/common/checkBox/UserPortalCheckBox.vue";
  import GroupApi from "@/api/GroupApi";
  import SnackbarType from "@/constants/SnackbarType";
  import LoggerUtils from "@/utils/LoggerUtils";
  import logLevels from "@/constants/logLevels";
  import Groups from "@/constants/groups";

  export default defineComponent({
    name: "GroupManagement",
    components: {
      UserPortalCheckBox
    },
    data() {
      return {
        loadingResources: false as boolean,
        performingApiAccessOperation: false as boolean,
        selectedGroups: [] as Array<string>,
        availableUtmGroups: [] as Array<string>,
        availableGrafanaGroups: [] as Array<string>,
        availableAirspaceManagerGroups: [] as Array<string>,
      }
    },
    props: {
      userName: {
        required: true,
        type: String
      },
      editUser: {
        required: false,
        type: Boolean,
        default: false
      }
    },
    watch: {
      userName: {
        immediate: true,
        async handler(): Promise<void> {
          this.loadingResources = true;
          if (this.editUser) {
            this.selectedGroups = await this.fetchGroupsForUser();
          }
          this.loadingResources = false;
        }
      },
      selectedGroups: {
        handler() {
          this.$emit('selected-groups-changed', this.selectedGroups)
        }
      }
    },
    async mounted() {
      this.loadingResources = true;
      const availableGroups = await this.fetchAllGroups();
      this.availableUtmGroups = availableGroups.filter(g => !g.startsWith("smartsis_") && !g.startsWith("GRAFANA_"));
      this.availableGrafanaGroups = availableGroups.filter(g => g.startsWith("GRAFANA_"));
      this.availableAirspaceManagerGroups = availableGroups.filter(g => g.startsWith("smartsis_"));
      this.selectedGroups = this.userName ? await this.fetchGroupsForUser() : [];
      this.loadingResources = false;
    },
    methods: {
      getOptionValue(option: string): string {
        return option;
      },
      getOptionName(option: string): string {
        return option;
      },
      async fetchAllGroups(): Promise<Array<string>> {
        try {
          const availableGroups = await GroupApi.getAllGroups();
          return availableGroups.filter(g => g !== Groups.UTM_ADMIN);
        } catch (e) {
          await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
            message: 'Failed to load available groups.',
            type: SnackbarType.ERROR
          });
          LoggerUtils.log(logLevels['error'], `Failed to load available groups. Reason: ${e.message}`);
          return Promise.resolve([]);
        }
      },
      async fetchGroupsForUser(): Promise<Array<string>> {
        try {
          const availableGroupsForUser = await GroupApi.getGroupsForUser(this.userName);
          return availableGroupsForUser.filter(g => g !== Groups.UTM_ADMIN);
        } catch (e) {
          await this.$store.direct.dispatch.snackbarModule.displaySnackbar({
            message: 'Failed to load groups for user.',
            type: SnackbarType.ERROR
          });
          LoggerUtils.log(logLevels['error'], `Failed to load groups for user. Reason: ${e.message}`);
          return Promise.resolve([]);
        }
      }
    }
  })
